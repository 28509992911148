.generic-web-menu-item {
  text-align: center;
  width: 48px;
  cursor: pointer;
  color: #818181;
  font-size: 12px;
  justify-content: center;
}

.web-menu-container {
  background-color: #fff;
  box-shadow: 0px 1px 0px 0px #00000040;
  padding: 8px 12px;
  position: relative;
  z-index: 9;
}

.detail-container {
  position: absolute;
  background-color: #fff;
  font-size: 13px;
  transform: translate3d(-20px, 20px, 0);
  right: 20px;
  min-width: 160px;
  text-align: left;
  border: 0.5px solid #ffefd7;
  border-radius: 5px;
  box-shadow: 0 0.5px 3px rgba(0, 0, 0, 0.25);
}

.detail-container a {
  color: #818181;
}

.detail-container::after {
  content: '';
  position: absolute;
  top: -11px;
  right: 15px;
  border-style: solid;
  border-width: 0 10px 11px;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
}

.detail-container p {
  margin: 0;
  padding: 5px 10px;
  cursor: pointer;
}
